.header {
    box-shadow: 0 0 19px -5px #bdbdbd;
    position: fixed;
    width: 100vw;
    padding-top: 10px;
    padding-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 30;
    height: 150px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 90px;
  }
  
  .header__logo {
    width: 100px;
    margin-left: 0%;
  }
  .header__logo > img {
    height: 4rem;
  }
  
  .header__center {
    flex: 1;
    display: flex;
    justify-content: center;
    margin-left: 7rem;
  }
  
  .header__center > p {
    margin: 10px;
    text-transform: uppercase;
  }
  
  .header__right {
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    flex: 1;
    margin-right: 8%;
  }
  
  .header__right > p {
    margin: 10px;
  }
  
  .header__right > i {
    margin-top: 7px;
  }
  



  