.body {
    text-align: center;
    margin-top: 150px;
}

@font-face {
    font-family: 'Gotham';
    src: local('Gotham'), url(/src/fonts/Gotham\ Book.otf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Gotham-Medium';
    src: local('Gotham-Medium'), url(/src/fonts/Gotham\ Medium.otf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'ProximaNova';
    src: local('ProximaNova'), url(/src/fonts/ProximaNova.otf) format('woff');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'EurostileBold';
    src: local('EurostileBold'), url(/src/fonts/EurostileBold.ttf) format('ttf');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

.body_container {
    /* max-width: 1140px; */
}

.mail-link {
    color: unset;
}

.body_title {
    width: 797px;
    height: 46px;
    margin: 126px auto 64px;
    font-family: Gotham;
    font-size: 46px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.69px;
    color: var(--dark);
}

.body_text {
    display: block;
    text-align: left;
}

.body_text_1 {
    margin-top: 4%;
    margin-left: 28%;
    width: 100%;
    font-size: 16px;
    font-family: Gotham-Medium;
    line-height: 22px;
    letter-spacing: 0;
    color: #47526d;
    font-weight: 600;
}

.body_text_2 {
    margin-top: 2.5%;
    margin-left: 28%;
    width: 100%;
    font-size: 16px;
    font-family: Gotham-Medium;
    line-height: 22px;
    letter-spacing: 0;
    color: #47526d;
    font-weight: 600;
}


.body_text_sub {
    margin: 2% auto;
    width: 50%;
    font-size: 16px;
    font-family: Gotham-Medium;
    line-height: 22px;
    letter-spacing: 0;
    color: #47526d;
}
.customInput:before {
    left: 0;
    right: 0;
    bottom: 0;
    content: "\00a0";
    position: absolute;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    pointer-events: none;
}

.customInput:after {
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    border-bottom: 2px solid #1e2535;
    pointer-events: none;
}

.inputCustom {
    width: 34% !important;
    margin-left: 34% !important;
    margin-top: 3% !important;
}

.customInput {
    margin: 0 6px 2px 0;
    font-family: Gotham;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #47526d;
    outline: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    width: 100%;
    margin-bottom: 1rem;
    font: inherit;
    border: 0;
    height: 1.1876em;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
}

.container_main_row {
    text-align: center;
}

.inputs_container {
    width: 25%;
    display: inline-block;
    text-align: center;
}

.button_container {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.buttonMLeft {
    margin-left: 8% !important;
}

.contenedorBaja {
    display: inline-block;
    width: 100%;
    text-align: center;
    align-items: center;
}

.buttonMRight {
    margin-right: 22% !important;
}

.inputCustomWidth {
    width: 68% !important;
    margin-top: 2rem !important;
    margin-bottom: 2.5rem !important;
}

.noMargin {
    margin-top: 0 !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
}

.body_button_baja {
    width: 290px;
    height: 63px;
    margin: 32px 215px 0;
    color: #fff;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    background-color: #ed1c23;
    padding: 0.9em 0;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.9em 0 !important;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Gotham HTF','Roboto', 'sans-serif';
    line-height: 1.75;
    border-radius: 3px;
    text-transform: uppercase;
}

.body_button {
    color: #fff;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    background-color: #ed1c23;
    width: 15%;
    padding: 0.9em 0;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.9em 0 !important;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: Gotham;
    line-height: 1.75;
    border-radius: 3px;
    text-transform: uppercase;
}

.body_button_disabled_Baja {
    width: 290px;
    height: 63px;
    margin: 32px 215px 0;
    color: whitesmoke;
    box-shadow: none;
    background-color: #e0e0e0;
    border: 0;
    cursor: default;
    pointer-events: none;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    padding: 0.9em 0;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.9em 0 !important;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-family: 'Gotham HTF','Roboto', 'sans-serif';
    line-height: 1.75;
    border-radius: 3px;
    text-transform: uppercase;
}

.body_button_disabled {
    color: whitesmoke;
    box-shadow: none;
    background-color: #e0e0e0;
    border: 0;
    cursor: default;
    pointer-events: none;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    width: 15%;
    padding: 0.9em 0;
    font-size: 0.9rem;
    font-weight: 600;
    font-family: Gotham;
    padding: 0.9em 0 !important;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    border-radius: 3px;
    text-transform: uppercase;
}

footer {
    width: 100%;
    padding: 0;
    margin-top: 10px;
}

.Social {
    margin-bottom: 1.5rem;
    font-family: ProximaNova;
    font-size: 20.8px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.94;
    letter-spacing: normal;
    color: #000;
}

.icon_mRight {
    margin-right: 1rem;
}

.facebook {
    background-image: url(/src/iconos/fb.svg);
    background-size: contain;
    background-repeat: no-repeat; 
    height: 30px;
    width: 30px;
}

.linkedin {
    background-image: url(/src/iconos/linked-in.svg);
    background-size: contain;
    background-repeat: no-repeat; 
    height: 30px;
    width: 30px;   
}

.youtube {
    background-image: url(/src/iconos/youtube.svg);
    background-size: contain;
    background-repeat: no-repeat; 
    height: 30px;
    width: 30px;   
}

.links_mRight {
    margin-right: 0.5rem;
}

.links_mLeft {
    margin-left: 0.5rem;
}

.footer-social {
    background-color: #fff;
    width: 100%;
    text-align: center;
    -webkit-text-size-adjust: 12px;
    -moz-text-size-adjust: 12px;
    -ms-text-size-adjust: 12px;
    text-size-adjust: 12px;
    margin-top: 70px;
}

.footer-logo-mobile {
    display: none;
}

.footer-logo-mobile .logo {
    margin: 20px 0;
}

.logo {
    margin-left: 5rem;
    display: inline-block;
    color: transparent;
    text-indent: -999px;
    overflow: hidden;
    width: 142px;
    height: 56px;
    background-image: url("/src/assets/logo_gpm.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
}
.footer-social-links {
    margin-bottom: 1rem;
}
.footer-social .footer-social-links {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.footer-info, .footer-info .footer-social-links {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #f4f4f4;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 10px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.footer-info .copy {
    margin-right: 2rem;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    font-weight: 700;
    margin: 20px 0;
    text-align: center;
    font-size: .8em;
}

.footer-last {
    background-color: #fff;
    padding: 0;
    width: 100%;
    text-align: right;
    -webkit-text-size-adjust: 12px;
    -moz-text-size-adjust: 12px;
    -ms-text-size-adjust: 12px;
    text-size-adjust: 12px;
}

.footer-float {
    position: fixed;
    bottom: 0;
    background-color: transparent;
    padding: 0;
    width: 100%;
    text-align: right;
    -webkit-text-size-adjust: 12px;
    -moz-text-size-adjust: 12px;
    -ms-text-size-adjust: 12px;
    text-size-adjust: 12px;
}

.footer-float .footer-float-logo {
    padding: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    float: right;
    -webkit-box-pack: right;
    -ms-flex-pack: right;
    justify-content: right;
    -webkit-box-align: right;
    -ms-flex-align: right;
    align-items: right;
}

.bg-blue {
    background-color: #293147;
    padding-bottom: 3rem!important;
    margin: 30px 0;
    padding: 50px 0 0;
}

.container_sup {
    display: inline-block;
    text-align: center;
    width: 100%;
}
.container_sub {
    display: flex;
}

.container_sub_1 {
    text-align: center;
    width: 100%;
}

.container_sub_middle {
    border-right: 1px solid white !important;
}

.container_sub_2 {
    text-align: center;
    width: 100%;

}

.body_button_sub {
    border: solid 1px #47526d;
    background-color: #fff;
    font-family: Gotham;
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: 1.1px;
    text-align: center;
    color: 293147;
    width: 290px;
    height: 55px;
    padding-top: 0.9em !important;
    padding-right: 20px !important;
    padding-bottom: 0.9em !important;
    padding-left: 20px !important;
    background-color: whitesmoke;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    padding: 0.9em 0;
    font-size: 0.9rem;
    font-weight: 600;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    border-radius: 3px;
    text-transform: uppercase;
}

.container_span_left {
    width: 40%;
    margin-left: 26%;
    height: 44px;
    margin: 16px 40px 32px 34%;
    font-family: Gotham;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.container_span_right {
    width: 40%;
    margin-left: 26%;
    height: 44px;
    margin: 16px 40px 32px 20%;
    font-family: Gotham;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.customSelect {
    outline: none;
    font-family: Gotham;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #47526d;
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
}

.customMarginTop {
    margin-top: 1rem !important; 

}

.customMargin {
    margin-bottom: 3rem !important; 
}

.container_Checks_Baja {
    width: 30%;
    margin-left: 34%;
    display: flex;
    text-align: left;
    margin-top: 2rem;
    font-size: 16px;
    font-family: Gotham;
    color: #47526d;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
    font-style: normal;
    letter-spacing: normal;
}

.container_Checks_Baja span {
    margin-left: 17px;
}

.container_Checks {
    display: flex;
    text-align: left;
    margin-top: 2rem;
}
.input_Check {
    width: 24px;
    height: 24px;
    border-radius: 2px;
    border: solid 2px #364059 !important;
    padding: 3px;
    object-fit: contain;
    margin-top: 0.3rem;
}

.input_Check:checked {
    color: black !important;
    background-color: white !important;
}

.input_Label_Check {
    display: flex;
    text-align: left;
    margin-top: 2rem;
    font-size: 16px;
    font-family: Gotham;
    color: #47526d;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.38;
    font-style: normal;
    letter-spacing: normal;
}

.regular-checkbox{
    -webkit-appearance: none;
	border: 2px solid #293147;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
	padding: 9px;
	border-radius: 3px;
	display: inline-block;
	position: relative;
}

.regular-checkbox:active, .regular-checkbox:checked:active {
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
}

.regular-checkbox:checked {
	border: 2px solid #293147;
	box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
	color: #293147;
}

.regular-checkbox:checked:after {
	content: '\2713';
	font-size: 20px;
	position: absolute;
	top: -4px;
	left: 2px;
	color: #293147;
}

.input_Label_Check span{
    margin-left: 17px;
}

.footer-links {
    /*margin-left: 6rem;*/
    width: 55%;
    font-size: 14px;
    background-color: #f4f4f4;

}

.footer-links a {
    color: black;
    text-decoration: none;
}

.customSelect::-ms-expand {
    background-color: blue;
    display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}

.modal-dialog {
    margin-left: 21% !important;
}

.modal-header {
    margin-right: -1%;
    border-bottom: 0 !important;
    padding-top: 0.5rem !important;
    padding-right: 1rem;
    padding-bottom: 0;
    padding-left: 0.5rem !important;
}

.modal-body {
    display: flex;
    height: 282px !important;
    align-items: center;
}

.modal-content {
    margin-top: 10rem !important;
    width: 807px !important;
}

.modal-footer {
    border-top: 0 !important;
}

.contentBody_1 {
    width: 35%;
    text-align: center;
    margin-right: 5%;
}

.contentBody_sub1 {
    /*margin-top: 25%;*/
    background-size: contain;
    background-repeat: no-repeat;
}

.contentBody_2 {
    width: 55%;
    text-align: left;
    /*margin-top: 7%;*/
}

.La-solicitud-de-dars_1 {
    width: 100%;
    /* font-family: ; */
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.27px;
    color: #293147;

}

.La-solicitud-de-dars {
    width: 80%;
    font-family: Gotham;
    font-size: 30px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 1.27px;
    color: #293147;

}

.La-ccion-ha-sido-co {
    font-family: Gotham;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #47526d;
}

.Esta-accin-podra-t {
    font-family: Gotham;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #47526d;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    border: 0;
    border-radius: .25rem;
    opacity: 1 !important;
}

.modal-header .btn-close {
    width: 24px;
    height: 28px;
    margin: 0 0 32px 52px;
    padding: 13px 11px 8px 4px;
    border-radius: 4px;
    background-color: #d71821;
}

.idEnModal {
    color: #d71821;
    font-family: Gotham;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
}

.consentimientoText {
    text-decoration: underline;
    cursor: pointer;
}

.consentimientoTextMargin{
    margin-left: 0.5rem;
    text-decoration: underline;
    cursor: pointer;
}

.input-error {
    color: red;
    position: relative;
    font-size: 14px;
}

.input-container {
    text-align: left;
}

.input-container input {
    margin-bottom: 2px;
}


@media (max-width: 640px) {
    .body_title {
        margin-bottom: 5rem;
        margin-left: 1rem;
        margin-right: 0;
        margin-top: 0;
        width: auto;
        height: 46px;
        font-size: 28px;
        text-align: left;
    }
    .logo {
        margin-left: 1rem;
    }
    .body_text_sub {
        font-size: 16px;
        width: 95%;
        margin-left: 3%;
    }
    .body_text_1 {
        margin-left: 3%;
        margin-bottom: 8%;
    }
    .body_text_2 {
        margin-top: 8.5%;
        margin-left: 3%;
        margin-bottom: 8%;
    }
    .inputs_container{
        margin-left: 3%;
        width: 95% !important;
    }
    .body_button_disabled {
        margin-top: 30px;
        margin-bottom: 30px;
        width: 290px;
        height: 55px;
    }
    .container_sub {
        display: inline-block;
        /* display: flex; */
    }
    .container_span_left {
        width: 90%;
        margin: 0 3px 32px 2px;
        text-align: left;
        margin-left: 6%;
    }
    .container_span_right {
        width: 90%;
        margin: 0 3px 32px 2px;
        text-align: left;
        margin-left: 6%;
    }
    .buttonMLeft {
        margin-left: 0% !important;
        margin-bottom: 2rem !important;
    }
    .buttonMRight {
        margin-right: 0% !important;
    }
    .container_sub_2 {
        margin-top: 2rem !important;
    }
    .container_sub_middle {
        display: inline-block;
        width: 80%;
        border-bottom: 1px solid white !important;
        border-right: none;
    }
    .footer-logo-mobile {
        display: block;
        width: 88%;
    }
    .footer-logo {
        display: none;
    }

    .Social {
        font-family: EurostileBold;
        font-size: 18px;
    }
    .footer-info {
        display: inline-block;
    }
    .footer-links {
        margin-left: 0rem;
        width: 100%;
        font-size: 14px;
        background-color: #f4f4f4;
    }
    .modal-dialog {
        margin-left: 0% !important;
        margin: 0;
    }
    .modal-content {
        width: auto !important;
    }
    .container_Checks_Baja {
        width: auto;
        margin-left: 0;
    }
    .inputCustom {
        width: 100% !important;
        margin-left: 0% !important;
        margin-top: 3% !important;
    }
    .body_button_disabled_Baja {
        margin-top: 32px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
    }
    .body_button_baja {
        margin-top: 32px;
        margin-right: 0px;
        margin-bottom: 0px;
        margin-left: 0px;
    }
    .contenedorBaja {
        width: 90% !important;
        margin-left: 5%;
    }

    .contenedorBaja_sub {
        margin-bottom: 3rem;
        margin-top: 2rem;
    }
    .contentBody_1 {
        width: auto;
        text-align: center;
        margin-right: 0%;
    }
    .contentBody_sub1 {
        margin-top: 0%;
    }
    .modal-body {
        display: inline-block;
        height: auto !important;
        width: 90% !important;
        margin-left: 5%;
    }
    .contentBody_2 {
        width: auto;
        text-align: center;
        margin-top: 7%;
    }
    .La-solicitud-de-dars {
        width: auto;
    }
    .inputCustomWidth {
        width: 100% !important;
    }
    .body_button {
        width: 50%;
    }
    .footer-social {
        margin-top: 0px;
    }
}

@media (min-width: 400px) and (max-width: 1120px) {
    .body_title {
        font-size: 32px;
    }
}
